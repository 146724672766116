.App {
  text-align: center;
}

.App-logo-section{
  float: left;
  /* height: 50.39px;   */
  /* background: #272827; */
  opacity:100%;  
  transition: opacity 0.9s ease-in-out;
}

.App-logo-section.opened-menu{
  visibility: hidden;
  opacity:0;  
  transition: opacity 0.5s ease-in-out;
}

.App-logo {
  height: 50px;  
  margin-top: 30px;
  margin-right: -106px;
  /*pointer-events: none;*/

}
.page-content{
  display: flex;
}

.page {
  display: flex;
  /*height: 92vh;*/
  margin-top: 60px;
  width: 100%;
  text-align: center;
  padding-bottom: 5px;
}

/*
@media (prefers-reduced-motion: no-preference) {
  img.App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}*/

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

footer{
  position: sticky;
  bottom: 0px;
  background: rgba(39, 40, 39, 0.8);
	display: flex;
	justify-content: space-between;	
  text-align: center;
	z-index: 12;
  padding-top:5px;
  height: 25px;
  margin-top: -25px;
  backdrop-filter:saturate(180%) blur(20px);
  width:100%;    
  }
  footer div{
    width: 100%;
  }